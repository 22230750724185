.map {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

@media (max-width: 721px) {
    .map {
        position: relative;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
    }
}
