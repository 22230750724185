.wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    cursor: pointer;
}

.isActive {
    background: #efefef;
}

.title {
    margin-bottom: 2px;
}

.subtitle {
}
