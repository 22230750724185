.wrapper {
    width: 300px;
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(-50%, -60px, 0);
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    @media (max-width: 728px) {
        position: fixed;
        height: auto;
        transform: translate3d(-50%, 0, 0);
        left: 50%;
        top: 15%;
        bottom: auto;
        z-index: 10;
    }
}

.closeWrapper {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.closeButton {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.closeIcon {
    height: 24px;
    width: 24px;
    color: #fff;
    &:hover {
        color: #f8fafc;
    }
}

.title {
    margin-bottom: 4px;
}

.body {
    margin-bottom: 8px;
}

.caption {
    margin-bottom: 16px;
}

.enterAddressManually {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}
