.zoomButtons {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.zoomButtons button {
    box-shadow: 0 4px 8px -2px #1018281a;
    cursor: pointer;
    background: #fff;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 6px;
    &:hover {
        background: #eef2f6;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: #e6eaf0;
}

.zoomButtons button:first-child {
    border-radius: 6px 6px 0 0;
}

.zoomButtons button:last-child {
    border-radius: 0 0 6px 6px;
}
