.wrapper {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

.closeButton {
    margin-top: 8px;
}

.actionButton {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}
