.background {
    z-index: 1040;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(34, 34, 34, 0.64);
    box-sizing: content-box;
}

.wrapper {
    z-index: 1040;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 12px;
    outline: none;
}

.controlPanel {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @media (max-width: 721px) {
        width: 100%;
        padding: 4px 0;
        order: 1;
    }
}

.container {
    display: flex;
    height: 100%;
    position: relative;
}

.content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.closeWrapper {
    position: absolute;
    z-index: 1041;
    top: 12px;
    right: 12px;
    padding: 4px;
    cursor: pointer;
    &:hover {
        .closeButton {
            background: #f8fafc;
            color: #f8fafc;
        }
    }
}

.closeButton {
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px -2px #1018280f, 0 4px 8px -2px #1018281a;
    height: 32px;
    width: 32px;
    color: #fff;
}

.closeIcon {
    height: 32px;
    width: 32px;
}

.overlayContent {
    position: relative;
    padding: 0;
    height: 100%;
}

.title {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-bottom: 4px;
    padding-right: 32px;
}

.subtitle {
    margin-bottom: 10px;
}

.large {
    width: 1040px;
    height: 630px;
    max-height: 100vh;
    max-width: calc(100% - 110px);
    overflow: hidden;

    .controlPanel {
        width: 360px;
        padding: 20px;
        overflow: auto;

        @media (max-height: 630px) and (min-width: 721px) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .closeButton {
        height: 40px;
        width: 40px;
    }
}

.normal {
    width: 440px;
    padding: 16px;
    border-radius: 8px;

    .title {
        margin-bottom: 8px;
        margin-left: 4px;
    }

    .container {
        flex-direction: column;
        max-height: calc(100vh - 32px);
    }

    .controlPanel {
        padding-right: 40px;
    }

    .closeWrapper {
        top: 0;
        right: 0;
        padding: 0;
    }

    .closeButton {
        background: transparent;
        box-shadow: none;
    }
}

.small {
    width: 360px;
    max-width: calc(100% - 36px);
    padding: 20px;
    border-radius: 8px;

    .controlPanel {
        @media (max-width: 721px) {
            order: initial;
        }
    }

    .closeWrapper {
        right: -8px;
        top: -8px;
        padding: 0;
    }

    .closeButton {
        background: transparent;
        box-shadow: none;
    }

    .closeIcon {
        width: 24px;
        height: 24px;
    }

    .container {
        flex-direction: column;
    }
}

.loaderContainer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
