.portal {
    position: relative;
}

.container {
    display: flex;
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    z-index: 1100;
    justify-content: center;

    @media (min-width: 768px) {
        bottom: 16px;
        left: 16px;
        top: auto;
        transform: none;
        max-width: 378px;
        justify-content: flex-start;
    }
}
