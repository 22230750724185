.controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 1;
    @media (max-width: 721px) {
        bottom: 200px;
    }
}

.geoButton {
    cursor: pointer;
    background: none;
    display: flex;
    padding: 0;
    border: none;
    margin-bottom: 8px;
    color: #547dff;
    &:hover {
        color: #486bdb;
    }
}

.geoIcon,
.geoLoaderWrap {
    width: 40px;
    height: 40px;
}

.geoLoaderWrap {
    background: #547dff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.geoLoader {
    filter: invert(1);
    width: 16px;
    height: 16px;
}
