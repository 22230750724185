:root {
    --border: rgba(34, 34, 34, 0.24);
    --bg: #fff;
    --color: #18181b;

    --hover-border: #cdd5df;
    --hover-bg: #ffffff;

    --disabled-border: #d1d1d6;
    --disabled-bg: #d1d1d6;
    --disabled-icon: #d1d1d6;
    --disabled-color: #d1d1d6;

    --selected-bg: #404040;
    --selected-hover-bg: #18181b;
    --selected-icon: #ffffff;
}

.withLabel {
    &:hover {
        background: #f8fafc;
        border-radius: 8px;

        .checkbox {
            border-color: var(--hover-border);
            background-color: var(--hover-bg);
        }
    }

    &.selected:hover {
        .checkbox {
            border-color: var(--selected-hover-bg);
            background-color: var(--selected-hover-bg);
        }
    }
}

.checkbox {
    composes: checkbox from "Checkbox.module.css";
    border-color: var(--border);
    background-color: var(--bg);

    &.disabled {
        border-color: var(--disabled-border);
        background-color: var(--disabled-bg);
    }
}

.selected > .checkbox {
    border-color: var(--selected-bg);
    background-color: var(--selected-bg);
}

.icon {
    composes: icon from "Checkbox.module.css";

    fill: var(--selected-icon);
}
.label {
    composes: label from "Checkbox.module.css";

    color: var(--color);
}

.disabled {
    > .checkbox {
        border-color: var(--disabled-bg);
        background-color: var(--disabled-bg);
    }

    .icon {
        fill: var(--disabled-icon);
    }

    .label {
        color: var(--disabled-color);
    }
}
