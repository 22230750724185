.wrapper {
    position: relative;
    z-index: 10;
}

.suggestsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    overflow: hidden;
    position: absolute;
    top: 100%;
    max-height: 300px;
    overflow-y: auto;
}

.searchInput {
    border: 1px solid #6c727e;
    border-radius: 6px;
    width: 100%;
    padding: 10px 12px;
    height: 48px;
}
