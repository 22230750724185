.title {
    padding: 16px;
}

.root {
    position: relative;
}

.wrapper {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.form {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.input {
    &:not(:last-child) {
        margin-bottom: 4px;
    }
}

.select {
    margin-bottom: 4px;
}

.legalInfo {
    display: block;
    padding: 2px 4px;
    margin-top: 8px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        order: 3;
        margin-bottom: 8px;
    }
}

.icon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
}

.buttons {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.button {
    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }

    @media (min-width: 768px) {
        flex: calc(50% - 8px);

        &:not(:last-of-type) {
            margin-right: 8px;
            margin-bottom: 0;
        }
    }
}

.row {
    display: flex;

    .input {
        flex: 1;
        margin-right: 4px;
        &:last-child {
            margin-right: 0;
        }
    }
}
