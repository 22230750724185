.wrapper {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

.infoButton {
    display: flex;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    padding: 8px 0;
    color: #547dff;

    svg {
        margin-right: 4px;
    }
}

.closeButton {
    margin-top: 8px;
}
