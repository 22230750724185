.container {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #fff;
    border-radius: 0 12px 12px 0;
    overflow: hidden;
    position: relative;

    @media (max-width: 721px) {
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100% - 120px);
        flex-direction: column;
        padding-top: env(safe-area-inset-top);
        &.hidden {
            display: none;
        }
    }
}

.mapWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    button {
        &:focus,
        &:active,
        &:hover {
            outline: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
        }
    }
}

.hidden {
    display: none;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1002;
    background: white;
}

.toast {
    margin-top: env(safe-area-inset-top);
}
