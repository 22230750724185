:root {
    --placeholder-color: #a0a0ab;
    --label-color: #a0a0ab;

    --caption-color: #18181b;

    --error-color: #fe4641;

    --default-background-color: #ffffff;
    --disabled-background-color: #f8fafc;

    --default-border: 1px solid #cdd5df;
    --hover-border: 1px solid #6c727e;
    --focus-border: 1px solid #6c727e;
    --disabled-border: 1px solid #f8fafc;
    --error-border: 1px solid #fe4641;

    --default-text-color: #18181b;
    --disabled-text-color: #d1d1d6;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.smallText {
    font-size: 11px;
    line-height: 12px;
}

.label {
    composes: smallText;

    color: var(--label-color);
    margin-bottom: 8px;
}

.placeholder {
    position: absolute;
    left: 0;

    color: var(--placeholder-color);

    transition: 0.2s ease all;
    pointer-events: none;
}

.caption {
    composes: smallText;

    color: var(--caption-color);
    margin-top: 4px;

    &.error {
        color: var(--error-color);
    }
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    border: var(--default-border);
    background-color: var(--default-background-color);
    color: var(--default-text-color);
    transition: 0.1s ease-out border-color;

    &:hover {
        border: var(--hover-border);
    }

    &.focused {
        border: var(--focus-border);
    }

    &.disabled {
        * {
            color: var(--disabled-text-color);
        }
        border: var(--disabled-border);
        background-color: var(--disabled-background-color);
        cursor: default;

        &:hover {
            border: var(--disabled-border);
            cursor: default;
        }
    }

    &.error {
        border: var(--error-border);
    }
}

.inputField {
    display: flex;
    align-items: center;
    background: none;
    width: 100%;
    cursor: text;
    appearance: none;
    overflow: hidden;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;

    font-size: 13px;
    line-height: 16px;
}

.inputFieldWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.attach {
    color: var(--placeholder-color);
    margin-left: 8px;

    .error {
        color: var(--error-color);
    }

    .clearable {
        cursor: pointer;
    }
}
