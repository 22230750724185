.overlay {
    position: fixed;
    z-index: 1000;
}

.backdrop {
    position: absolute;
    opacity: 0;
    background: #000;
}

.overlay,
.backdrop {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.content {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 16px 16px 12px 16px;
    background-color: #fff;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 16px;
}

.title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 32px;
    text-align: left;
    margin-bottom: 0;
}
.subtitle {
    margin-top: 8px;
}
.hasBackButton {
    min-height: 32px;
    padding-left: 32px;
    text-align: center;
    justify-content: center;
}

.leftOffset {
    margin-left: -24px;
}

.crossOffset {
    margin-right: -24px;
}

.icon {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    color: #fff;
    position: absolute;
    top: 16px;
    &:hover {
        color: #f8fafc;
    }
}
.leftIcon {
    left: 16px;
}
.rightIcon {
    right: 16px;
}

.footer {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
}

.closing .backdrop,
.opening .backdrop,
.opening .content,
.closing .content {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.opening .backdrop {
    animation-name: backdropShowAnimation;
}

.opened .backdrop {
    opacity: 0.5;
}

.closing .backdrop {
    animation-name: backdropHideAnimation;
}

.wrap {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex: 1 1;

    &::-webkit-scrollbar {
        display: none;
    }
}

.top .wrap,
.bottom .wrap {
    max-height: 90vh;
    overflow-y: scroll;
}

.right .wrap,
.left .wrap {
    max-height: 100vh;
    overflow-y: scroll;
}

.top .wrap.hasHeader,
.bottom .wrap.hasHeader {
    //max-height: calc(90vh - 50px);
}

.right .wrap.hasHeader,
.left .wrap.hasHeader {
    max-height: calc(100vh - 50px);
}

/* bottom */

.bottom .content {
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    transform: translateY(100%);
}

.bottom .contentFloating {
    bottom: 24px;
    left: 12px;
    right: 12px;
    border-radius: 12px;
    transform: translateY(100%);
    overflow: hidden;
}

.opening.bottom .content {
    animation-name: contentShowAnimationBottom;
}

.opened.bottom .content {
    transform: translateY(0);
}

.closing.bottom .content {
    animation-name: contentHideAnimationBottom;
}

/* right */

.right .content {
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    transform: translateX(100%);
}

.opening.right .content {
    animation-name: contentShowAnimationRight;
}

.opened.right .content {
    transform: translateX(0);
}

.closing.right .content {
    animation-name: contentHideAnimationRight;
}

/* left */

.left .content {
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
}

.opening.left .content {
    animation-name: contentShowAnimationLeft;
}

.opened.left .content {
    transform: translateX(0);
}

.closing.left .content {
    animation-name: contentHideAnimationLeft;
}

.withTopAnchor {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 42px;
    height: 4px;
    border-radius: 101px;
    background: #d1d1d6;
}

/* top */

.top .content {
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 12px 12px;
    transform: translateY(-100%);
    overflow: hidden;
}

.opening.top .content {
    animation-name: contentShowAnimationTop;
}

.opened.top .content {
    transform: translateY(0);
}

.closing.top .content {
    animation-name: contentHideAnimationTop;
}

/* fixed size */

.bottom.fixedSize .content,
.top.fixedSize .content {
    height: calc(100% - 30px);
}

.left.fixedSize .content,
.right.fixedSize .content {
    width: 80%;
}

/* fullscreen size */

.bottom.fullscreenSize .content,
.top.fullscreenSize .content {
    height: 100%;
}

.bottom.fullscreenSize .contentFloating,
.top.fullscreenSize .contentFloating {
    top: 12px;
    height: 95%;
}

.left.fullscreenSize .content,
.right.fullscreenSize .content {
    width: 100%;
}

.top.fullscreenSize .wrap,
.bottom.fullscreenSize .wrap {
    max-height: 100%;
}

/* animations */

@keyframes backdropShowAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes backdropHideAnimation {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
}

@keyframes contentShowAnimationBottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes contentHideAnimationBottom {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes contentShowAnimationRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes contentHideAnimationRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes contentShowAnimationLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes contentHideAnimationLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes contentShowAnimationTop {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes contentHideAnimationTop {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}
