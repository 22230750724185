.loader {
    width: 16px;
    height: 16px;
    animation: spinAnimation 2s linear infinite;
}

@keyframes spinAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
