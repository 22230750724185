.root {
    display: inline-flex;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
    padding: 4px;
}

.checkbox {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border: 1px solid #cdd5df;
    border-radius: 6px;
    text-align: left;
    box-shadow: none;
}

.disabled {
    cursor: default;
}

.input {
    display: none;
}

.icon {
    width: 14px;
    height: 14px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.label {
    display: inline-block;
    vertical-align: -3px;
    margin-left: 4px;
    white-space: initial;
}
