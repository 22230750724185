:root {
    --icon-size: 14px;

    --input-border-radius: 6px;
    --input-height: 48px;
}

.icons {
    composes: icons from "Input.module.css";

    width: var(--icon-size);
    height: var(--icon-size);
}

.prefix {
    margin: 2px 0 2px 8px;
}

.attach {
    composes: attach from "Input.module.css";

    margin: 2px 8px 2px 0;
}

.input {
    composes: input from "Input.module.css";

    border-radius: var(--input-border-radius);
    height: var(--input-height);
}

.leftLayout {
    margin-left: 12px;
}

.rightLayout {
    margin-right: 12px;
}

.placeholder {
    composes: placeholder from "Input.module.css";

    &Default {
        font-size: 13px;
        line-height: 16px;
        color: #d1d1d6;
    }

    &Focused {
        top: 8px;
        font-size: 11px;
        line-height: 12px;
    }
}

span.placeholderDefault {
    color: #d1d1d6;
}

.upPlaceholder {
    top: auto;
    bottom: 8px;
}
