.wrapper {
    display: flex;
    margin-top: 4px;
}

.input {
    flex: 1;
    margin-right: 4px;
}

.input:last-child {
    margin-right: 0;
}
