.wrapper {
    margin-top: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #898993;

    @media (max-width: 721px) {
        line-height: 1;
    }
}

.link {
    color: #547dff;
}
