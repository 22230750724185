.wrapper {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

.closeButton {
    margin-top: 16px;
}

.caption {
    margin-top: 8px;
    color: #a0a0ab;

    a {
        color: #547dff;
    }
}
