:root {
    --border: rgba(34, 34, 34, 0.24);
    --bg: #fff;
    --color: #222;

    --hover-border: rgba(34, 34, 34, 0.64);
    --icon-color: #a0a0ab;

    --disabled-color: rgba(34, 34, 34, 0.4);
    --disabled-bg: rgba(34, 34, 34, 0.12);
}

.root {
    position: relative;
}

.icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    color: #33c83d;
}

.dropdownMenu {
    top: 100%;
    position: absolute;
    width: calc(100% - 4px);
    left: 2px;
    overflow-y: auto;
    background-color: var(--bg);
    margin-top: 2px;
    z-index: 1;
    border-radius: 6px;
    transition: opacity 300ms ease-in-out, max-height 300ms ease;
    max-height: calc(32px * 4);

    &Open {
        box-shadow: 0 2px 4px -2px #1018280f, 0 4px 8px -2px #1018281a;
        padding: 4px 0;
    }
}

.list {
    height: 100%;
    overflow: scroll;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
        background: #f8fafc;
    }
}

.selected {
    background: #f8fafc;
}

.title {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.chevronOpen {
    transform: rotate(180deg);
}

.select {
    cursor: pointer;
}
