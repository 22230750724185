:root {
    --icon-size: 16px;

    --input-border-radius: 6px;
    --input-height: 40px;
}

.icons {
    composes: icons from "Textarea.module.css";

    width: var(--icon-size);
    height: var(--icon-size);
}

.prefix {
    margin: 2px 0 2px 2px;
}

.attach {
    composes: attach from "Textarea.module.css";

    margin: 2px 12px 2px 8px;
}

.input {
    composes: input from "Textarea.module.css";

    border-radius: var(--input-border-radius);
    height: var(--input-height);
}

.leftLayout {
    margin-left: 12px;
}

.rightLayout {
    margin-right: 12px;
}

.placeholder {
    composes: placeholder from "Textarea.module.css";

    &Default {
        font-size: 13px;
        line-height: 16px;
    }

    &Focused {
        top: 4px;
        font-size: 11px;
        line-height: 12px;
    }
}

.upPlaceholder {
    top: 16px;
}
