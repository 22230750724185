.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: 721px) {
        flex-shrink: 0;
        padding-top: 4px;
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 721px) {
    .content {
        flex: 0;
        flex-basis: 70px;
        background: #fff;
        z-index: 101;
        padding-top: 8px;
        position: relative;
    }

    .fullscreen .content {
        flex: 1;
    }

    .wrapper.fullscreen {
        width: 100%;
        height: 100%;
        background: #fff;
    }

    .wrapper {
        /*отступ сверху вместо карты*/
        &::before {
            content: "";
            flex: 1;
        }
        &.fullscreen::before {
            flex: 0;
        }
    }
}

.addressPreview {
    margin-top: 24px;

    &Button {
        display: flex;
        align-items: center;
        text-align: left;
        background: none;
        outline: none;
        border: none;
        padding: 8px 0;
        color: #547dff;
    }
    &Text {
        display: inline-flex;
        align-items: center;
    }
    &Icon {
        margin-right: 4px;
    }
}

.errorOverlay:before {
    position: absolute;
    z-index: 1000;
    content: "";
    background: #fff;
    opacity: 0.7;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 12px;
}

.errorGhost {
    z-index: 1000;
    height: 80%;
    position: absolute;
    right: 0;
    transform: translate3d(30%, 0, 0);
    top: 10%;
    opacity: 0.05;
    color: #000;
}

.enterManually {
    cursor: pointer;
    margin: 8px 0;
    color: #547dff;
    fill: #547dff;
    display: flex;
    align-items: center;
    &Text {
        margin-left: 8px;
    }
}
