.wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
}

.bottomShadow {
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    content: "";
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    background: radial-gradient(ellipse, rgba(0, 0, 0, 0.05) 50%, transparent 100%);
    transition: transform 0.3s ease-in;
    transform: translate3d(-50%, 0, 0);
}

.pin {
    position: absolute;
    bottom: 0;
}

.pinIcon {
    position: relative;
    z-index: 100;
    transition: transform 0.3s ease-in;
    transform: translate3d(-50%, 0, 0);
}

.pin.active .bottomShadow {
    transform: translate3d(-50%, 4px, 0) scale(1.4);
}

.pin.active .pinIcon {
    transform: translate3d(-50%, -8px, 0);
}
